import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React, { Fragment } from 'react';
import Anchor from '../../../atoms/Anchor';
import List from '../../../molecules/List/List';
import ListItem from '../../../molecules/List/ListItem';
import PhoneIcon from '../../../../static/images/icons/Phone';
import SmsIcon from '../../../../static/images/icons/Comment';
import LocationIcon from '../../../../static/images/icons/Location';
import { getAddressURL, getCompleteAddress, convertPhoneFormat } from '../../../../utils/utilityFunction';
import analyticsConstants from '../../../../constants/analyticsConstants';
import { locationLabels } from '../../../../constants/labels/en';
var footerAnalytics = analyticsConstants.globalFooter;
var contactInformationData = {
  Address: {
    Icon: LocationIcon,
    dataAnalyticsType: footerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: footerAnalytics.analyticsValue.GLOBAL_NAV_ADDRESS,
    title: function title(address) {
      return address;
    },
    url: function url(addressUrl) {
      return addressUrl;
    }
  },
  Email: {
    Icon: null,
    dataAnalyticsType: footerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: footerAnalytics.analyticsValue.GLOBAL_NAV_EMAIL,
    title: function title(email) {
      return email;
    },
    url: function url(email) {
      return "mailto:".concat(email);
    }
  },
  Fax: {
    Icon: PhoneIcon,
    dataAnalyticsType: footerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: footerAnalytics.analyticsValue.GLOBAL_NAV_FAX,
    title: function title(fax) {
      return convertPhoneFormat(fax);
    },
    url: function url(fax) {
      return "tel:".concat(convertPhoneFormat(fax));
    }
  },
  Phone: {
    Icon: PhoneIcon,
    dataAnalyticsType: footerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: footerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    title: function title(phone) {
      return "".concat(convertPhoneFormat(phone));
    },
    url: function url(phone) {
      return "tel:".concat(convertPhoneFormat(phone));
    }
  },
  Text: {
    Icon: SmsIcon,
    dataAnalyticsType: footerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: footerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    title: function title(sms) {
      return "".concat(convertPhoneFormat(sms));
    },
    url: function url(sms) {
      return "sms: ".concat(convertPhoneFormat(sms));
    }
  }
};
var ContactAnchor = function ContactAnchor(_ref) {
  var className = _ref.className,
    type = _ref.type,
    label = _ref.label,
    contactTitle = _ref.contactTitle,
    contactUrl = _ref.contactUrl,
    icon = _ref.icon;
  if (!contactTitle || !contactUrl) return;
  var _contactInformationDa = contactInformationData[type],
    Icon = _contactInformationDa.Icon,
    dataAnalyticsType = _contactInformationDa.dataAnalyticsType,
    dataAnalyticsValue = _contactInformationDa.dataAnalyticsValue;
  var url = contactInformationData[type].url(contactUrl);
  var title = contactInformationData[type].title(contactTitle);
  var ContactIcon;
  if (icon !== null && icon !== void 0 && icon.embedCode) {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-footer__contact-icon",
        "aria-hidden": "true",
        dangerouslySetInnerHTML: {
          __html: icon.embedCode
        }
      });
    };
  } else if (icon !== null && icon !== void 0 && icon.image) {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-footer__contact-icon"
      }, __jsx("img", {
        src: icon.image.url,
        alt: ""
      }));
    };
  } else {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-footer__contact-icon"
      }, __jsx(Icon, null));
    };
  }
  return __jsx(ListItem, {
    className: className
  }, __jsx(Anchor, {
    title: title,
    ctaBehavior: "Open in a new page",
    className: "nva-footer__contact-item u-caption",
    to: url,
    "data-analytics-type": dataAnalyticsType,
    "data-analytics-value": dataAnalyticsValue
  }, __jsx(ContactIcon, null), label && __jsx("span", {
    className: "nva-footer__contact-label"
  }, label), " ", title));
};
var ContactItem = function ContactItem(_ref2) {
  var type = _ref2.type,
    label = _ref2.label,
    icon = _ref2.icon,
    contactData = _ref2.contactData;
  var _contactData$type = contactData === null || contactData === void 0 ? void 0 : contactData[type],
    className = _contactData$type.className,
    contactTitle = _contactData$type.title,
    contactUrl = _contactData$type.url;
  return __jsx(ContactAnchor, {
    className: className,
    type: type,
    label: label,
    contactTitle: contactTitle,
    contactUrl: contactUrl,
    icon: icon
  });
};
var ContactInformation = function ContactInformation(_ref3) {
  var items = _ref3.items,
    yextContactInformationData = _ref3.yextContactInformationData;
  var contactItems = items ? items : [{
    type: 'Name',
    label: yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.name
  }, {
    type: 'Address',
    label: locationLabels.addressCamelCase
  }, {
    type: 'Email',
    label: locationLabels.email
  }, {
    type: 'Fax',
    label: locationLabels.fax
  }, {
    type: 'Phone',
    label: locationLabels.phone
  }, {
    type: 'Text',
    label: locationLabels.sms
  }];
  var contactItemsFiltered = contactItems.filter(function (item) {
    return item.type != 'Name';
  });
  var nameItem = contactItems.filter(function (item) {
    return item.type == 'Name';
  })[0];
  if (nameItem) nameItem = _objectSpread(_objectSpread({}, nameItem), {}, {
    label: yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.name
  });
  var GMBUrl = (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_googleMyBusinessCID) && "https://maps.google.com/maps?cid=".concat(yextContactInformationData.c_googleMyBusinessCID);
  var phone = (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_websitePhone) || (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.mainPhone);
  var sms = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_textPhone;
  var address = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.address;
  var fax = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.fax;
  var email = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.emails;
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var addressStringURL = getAddressURL(addressString);
  var contactData = {
    Address: {
      title: addressString,
      url: GMBUrl || addressStringURL,
      className: 'nva-footer__contact-address'
    },
    Email: {
      title: email,
      url: email,
      className: 'nva-header__contact-email'
    },
    Fax: {
      title: fax,
      url: fax,
      className: 'nva-header__contact-fax'
    },
    Phone: {
      title: phone,
      url: phone,
      className: 'nva-footer__contact-phone'
    },
    Text: {
      title: sms,
      url: sms,
      className: 'nva-footer__contact-sms'
    },
    'Hours of Operation': {}
  };
  return __jsx("div", {
    className: "nva-footer__contact"
  }, nameItem && __jsx("h3", {
    className: "nva-footer__contact-item-name"
  }, nameItem.label), __jsx(List, {
    className: "nva-footer__contact-list"
  }, contactItemsFiltered.map(function (_ref4) {
    var type = _ref4.type,
      label = _ref4.label,
      icon = _ref4.icon;
    return __jsx(Fragment, {
      key: type
    }, __jsx(ContactItem, {
      type: type,
      label: label,
      icon: icon,
      contactData: contactData
    }));
  })));
};
export default ContactInformation;